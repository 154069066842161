<template>
  <div>
    <Breadscrumbs :items="breadcrumbs" class="header-bread-scrum" />
    <v-layout column>
      <v-layout v-if="checkRoleBtn" justify-end class="mb-3">
        <v-btn color="primary" @click="onCreateCustomer">
          <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>
          {{ $t('create_customer') }}
        </v-btn>
      </v-layout>
      <v-layout justify-space-between class="mb-3">
        <v-layout class="col-10 pa-0">
          <v-text-field
            v-model="objSearch"
            outlined
            dense
            hide-details
            clearable
            class="col-3"
            style="border-radius: 10px"
            :placeholder="`${$t('place_holders.name')}/${$t('customer_phone')}`"
            @keydown.enter="onClickSearch"
          />
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                append-icon="mdi-calendar"
                outlined
                dense
                hide-details=""
                readonly
                clearable
                :value="dateRangeText"
                :placeholder="$t('place_holders.registration_period')"
                class="mx-3 col-3 border-field"
                v-bind="attrs"
                v-on="on"
                @input="onInputDateRange"
              />
            </template>
            <v-date-picker
              v-model="dates"
              range
              no-title
              :locale="locale"
              :min="minAllowedDate"
              :max="maxAllowedDate"
            >
              <v-spacer />
              <v-btn text color="primary" @click="menu = false">
                {{ $t('cancel') }}
              </v-btn>
              <v-btn text color="primary" @click="onSelectedDates">
                {{ $t('select') }}
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-autocomplete
            v-if="lstPostOffice.length > 0"
            v-model="selectPostOffice"
            outlined
            dense
            clearable
            hide-details
            :filter="filterItems"
            class="rounded-lg mr-2 col-3"
            :no-data-text="$t('no-data')"
            :placeholder="$t('place_holders.choice-post-office')"
            :items="lstPostOffice"
            item-text="name"
            item-value="id"
          />
          <v-select
            v-model="customerSelect"
            style="flex: 20"
            outlined
            dense
            class="rounded-lg col-3"
            item-text="name"
            item-value="code"
            :items="lstCustomer"
            :placeholder="$t('type-customer')"
          />
        </v-layout>
        <v-btn color="primary" class="col-1" @click="onClickSearch">
          {{ $t('search') }}
        </v-btn>
      </v-layout>
      <v-data-table
        class="border-primary"
        hide-default-footer
        fixed-header
        :no-data-text="$t('not_found')"
        :headers="headers"
        :header-props="{ sortIcon: 'mdi-menu-swap' }"
        :items-per-page="20"
        :items="items"
        height="70vh"
        @click:row="viewDetail"
      >
        <template v-slot:[`item.name`]="{ item }">
          <td class="max-width-7 text-truncate">
            {{ item.name }}
            <v-icon v-if="item.status === constants.statusCustomer.off" color="black">mdi-lock-outline</v-icon>
          </td>
        </template>
        <template v-slot:[`item.email`]="{ item }">
          <td class="max-width-7 text-truncate">
            {{ item.email }}
          </td>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ formatDate(item.createdAt) }}
        </template>
        <template v-slot:[`item.customerType`]="{ item }">
          <td class="max-width-6 text-truncate">
            {{ getTextCustomer(item.type) }}
          </td>
        </template>
        <template v-slot:footer>
          <custom-pagination
            :page="page"
            :start-record="computedStartRecord"
            :end-record="computedEndRecord"
            :total-record="totalRecord"
            :total-page="totalPage"
            :on-change-page="onChangePage"
          />
        </template>
      </v-data-table>
    </v-layout>

    <!-- Dialog loading -->
    <dialog-loading v-model="showLoading" :message="$t('processing')" />

    <!-- notifications -->
    <dialog-notification
      v-model="showNoti"
      :icon-src="getIcon"
      :message="message"
    />

    <dialog-confirm-token-expried v-model="showConfirmTokenExpried" />
  </div>
</template>

<script>
import Breadscrumbs from '@/components/base/Breadscrumbs'
import DialogLoading from '@/components/dialog/DialogLoading.vue'
import DialogNotification from '@/components/dialog/DialogNotification.vue'
import DialogConfirmTokenExpried from '@/components/dialog/DialogConfirmTokenExpried.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import constants from '@/constants'
import moment from 'moment'
import routePaths from '@/router/routePaths'
import filterItems from "@/helpers/filterItems";
import { mapActions } from 'vuex'
import { StringUtils } from '@/helpers/stringUtils'
import { DateTimeUtils } from '@/helpers/dateTimeUtils'
import { UserService } from '@/services/userService'
import { DataUtils } from '@/helpers/dataUtils'
import { StorageService } from '@/services/storageService'
import { PostService } from "@/services/postService";

export default {
  components: {
    DialogLoading,
    DialogNotification,
    DialogConfirmTokenExpried,
    CustomPagination,
    Breadscrumbs
  },
  mixins: [filterItems],
  data() {
    return {
      objSearch: '',
      constants,
      menu: false,
      dates: [],
      datesFm: [],
      headers: [
        {
          text: this.$t('customer_name'),
          value: 'name',
          width: '25%',
          class: 'text-subtitle-2'
        },
        {
          text: this.$t('sdt'),
          sortable: false,
          value: 'phone',
          width: '10%',
          class: 'text-subtitle-2'
        },

        {
          text: this.$t('email'),
          value: 'email',
          width: '15%',
          class: 'text-subtitle-2'
        },
        {
          text: this.$t('post_offices'),
          value: 'poName',
          width: '15%',
          sortable: true,
          class: 'text-subtitle-2'
        },
        { text: this.$t('address'), value: 'provinceName', width: '15%', class: 'text-subtitle-2' },
        { text: this.$t('registed_date'), value: 'createdAt', width: '10%', class: 'text-subtitle-2' },
        {
          text: this.$t('customer-type'),
          sortable: false,
          value: 'customerType',
          width: '15%',
          class: 'text-subtitle-2'
        }
      ],
      items: [],
      totalPage: 0,
      page: 1,
      totalRecord: 0,

      // notifications
      showNoti: false,
      typeNoti: constants.typeAlert.warning,
      message: '',
      showLoading: false,
      showConfirmTokenExpried: false,
      customerSelect: null,
      currentRole: JSON.parse(localStorage.getItem("CURRENT_USER")).roles,
      lstCustomer: [
        {
          name: this.$t('personal'),
          code: 1
        },
        {
          name: this.$t('shop'),
          code: 2
        },
        {
          name: this.$t('choice-customer'),
          code: null
        },
      ],
      breadcrumbs: [
        {
          text: 'list_customers',
          disabled: true,
          isActive: true,
        },
      ],
      lstPostOffice: [],
      selectPostOffice: null
    }
  },
  computed: {
    minAllowedDate() {
      if (this.dates && this.dates.length === 1) {
        return this.dates[0]
      }
      return null
    },
    maxAllowedDate() {
      return moment().format(constants.formatDateYmd)
    },
    dateRangeText() {
      return this.datesFm.join(' - ')
    },
    computedStartRecord() {
      if (this.items !== null && this.items.length > 0) {
        return (this.page - 1) * constants.itemsPerPage + 1
      } else {
        return 0
      }
    },
    computedEndRecord() {
      if (this.items !== null && this.items.length > 0) {
        return (this.page - 1) * constants.itemsPerPage + this.items.length
      } else {
        return (this.page - 1) * constants.itemsPerPage
      }
    },
    getIcon() {
      return StringUtils.getIconSrc(this.typeNoti)
    },
    checkRoleBtn() {
      return this.currentRole.includes("ROLE_ADMIN")
    },
  },
  watch: {
    dates(val) {
      const newDates = []
      val.forEach((i) => {
        const item = this.formatDate(i)
        newDates.push(item)
      })
      this.datesFm = newDates
    }
  },
  created() {
    if (window.location.pathname && (!this.currentRole.includes("ROLE_ADMIN") && !this.currentRole.includes("ROLE_SALE") && !this.currentRole.includes("ROLE_ROOT"))) {
      this.toggleDialogNoti({
        state: true,
        msg: this.$t("no-mission-msg"),
      });
      setTimeout(() => {
        this.$router.go(-1);
      }, constants.timeOutRole);
    }
  },
  beforeMount() {
    this.initData()
  },
  methods: {
    ...mapActions('layout', ['setBreadcrumbs']),
    async initData() {
      this.search()
      this.searchPostOffice()
    },
    async search() {
      this.showLoading = true
      const pars = this.bindRequestPars()
      const { status, data } = await UserService.searchCustomer(pars)
      this.showLoading = false
      if (status === constants.statusCode.ok && data) {
        this.totalRecord = data.totalRecord
        this.totalPage = DataUtils.calculatePage(data.totalRecord)
        if (this.totalRecord > 0) {
          this.items = data.data
        } else {
          this.items = []
        }
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message })
      }
    },
    bindRequestPars() {
      const pars = {
        currentPage: this.page,
        limit: constants.itemsPerPage,
        objSearch: '',
        status: ''
      }
      if (this.customerSelect) {
        pars['customerType'] = this.customerSelect
      }

      if (this.objSearch) {
        pars.objSearch = this.objSearch.trim()
      }

      if (this.selectPostOffice) {
        pars["poId"] = this.selectPostOffice
      }

      if (this.dates && this.dates.length > 0) {
        pars['fromDate'] = this.dates[0].trim()

        if (this.dates.length > 1) {
          pars['toDate'] = this.dates[1].trim()
        }
      }

      return pars
    },
    async searchPostOffice() {
      this.showLoading = true;
      const pars = this.bindRequestParsOffice();
      const { status, data } = await PostService.getPostOffice(pars);
      this.showLoading = false;
      if (status === constants.statusCode.ok && data) {
        if (data.totalRecord > 0) {
          this.lstPostOffice = data.data;
        } else {
          this.lstPostOffice = [];
        }
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message });
      }
    },
    bindRequestParsOffice() {
      const pars = {
        keyword: "",
        status: 1,
        limit: 1000,
        currentPage: this.page,
      }
      return pars
    },
    getTextCustomer(item) {
      if (item === 1) {
        return this.$t('personal')
      }
      return this.$t('shop')
    },
    viewDetail(item) {
      this.$router.push({
        path: routePaths.CUSTOMER_DETAIL,
        query: { id: item.id }
      })
      // this.addBreadCrumbs(routePaths.CUSTOMER_DETAIL)
    },
    onCreateCustomer() {
      this.$router.push(routePaths.CUSTOMERS_CREATE);
    },
    onClickSearch() {
      this.page = 1
      this.search()
    },
    onChangePage(page) {
      this.page = page
      this.search()
    },
    onSelectedDates() {
      this.$refs.menu.save(this.dates)
    },
    formatDate(date) {
      return DateTimeUtils.formatDateTime(date, constants.formatDate)
    },
    formatDateYmd(date) {
      return DateTimeUtils.formatDateTime(date, constants.formatDate)
    },
    parseDate(date) {
      return DateTimeUtils.parseDate(date)
    },
    onInputDateRange(val) {
      if (!val) {
        this.dates = []
      }
    },
    toggleDialogNoti(
      pars = { state: false, type: constants.typeAlert.warning, msg: '' }
    ) {
      this.showNoti = pars.state
      this.typeNoti = pars.type
      this.message = pars.msg
    }
  }
}
</script>
<style src="@/styles/createMultiOrder.scss" lang="scss">
</style>
